<template>
  <div>
    <form-wizard
      color="#FF8130"
      title="Crear Cuenta"
      subtitle="Diligencia el siguiente formulario para administrar tu casillero"
      finish-button-text="Crear Cuenta"
      back-button-text="Anterior"
      next-button-text="Siguiente"
      class="steps-transparent mb-3"
      @on-complete="registerClient"
    >
      <!-- información personal -->
      <tab-content
        :title="titleTabInformation"
        :before-change="validationFormInfo"
      >
        <validation-observer ref="personalRules">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Tipo de cuenta</h5>
              <small class="text-muted"> Selecciona el tipo de cuenta </small>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="" label-for="i-accountType">
                <validation-provider
                  #default="{ errors }"
                  name="Tipo_de_Cuenta"
                  rules="required"
                >
                  <b-form-select
                    id="i-accountType"
                    v-model="formRegisterData.accountType"
                    :options="optionsAccountType"
                    @change="onAccounTypeInput()"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
              v-if="formRegisterData.accountType == '1'"
            >
              <h5 class="mb-0">Información personal</h5>
              <small class="text-muted">Ingresa tu información personal</small>
            </b-col>
            <b-col cols="12" class="mb-2" v-else>
              <h5 class="mb-0">Información de tu empresa</h5>
              <small class="text-muted"
                >Ingresa la información de tu empresa</small
              >
            </b-col>
            <!-- Tipo de identificación -->
            <b-col md="6" v-if="formRegisterData.accountType == '1'">
              <b-form-group
                label="Tipo de identificación"
                label-for="i-identificationType"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tipo_de_identificacion"
                  rules="required"
                  v-if="formRegisterData.accountType == '1'"
                >
                  <b-form-select
                    id="i-identificationType"
                    v-model="formRegisterData.identificationType"
                    :options="optionsIdentificationType"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Razón social -->
            <b-col md="6" v-if="formRegisterData.accountType == '2'">
              <b-form-group label="Razón social" label-for="i-companyName">
                <validation-provider
                  #default="{ errors }"
                  name="Razon_social"
                  rules="required"
                  v-if="formRegisterData.accountType == '2'"
                >
                  <b-form-input
                    id="i-companyName"
                    v-model="formRegisterData.companyName"
                    placeholder=""
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Número de identificación -->
            <b-col md="6">
              <b-form-group
                label="Nit o Número de identificación"
                label-for="i-identificationNumber"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Numero_de_identificacion"
                  rules="required"
                >
                  <b-form-input
                    id="i-identificationNumber"
                    v-model="formRegisterData.identificationNumber"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Primer nombre -->
            <b-col md="6">
              <b-form-group label="Primer nombre" label-for="i-firstName">
                <validation-provider
                  #default="{ errors }"
                  name="Primer_nombre"
                  rules="required"
                >
                  <b-form-input
                    id="i-firstName"
                    v-model="formRegisterData.firstName"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Segundo nombre -->
            <b-col md="6">
              <b-form-group label="Segundo nombre" label-for="i-secondName">
                <b-form-input
                  id="i-secondName"
                  v-model="formRegisterData.secondName"
                  placeholder=""
                />
              </b-form-group>
            </b-col>
            <!-- Primer apellido -->
            <b-col md="6">
              <b-form-group label="Primer apellido" label-for="i-firstLastName">
                <validation-provider
                  #default="{ errors }"
                  name="Primer_apellido"
                  rules="required"
                >
                  <b-form-input
                    id="i-firstLastName"
                    v-model="formRegisterData.firstLastName"
                    placeholder=""
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Segundo apellido -->
            <b-col md="6">
              <b-form-group
                label="Segundo apellido"
                label-for="i-secondLastName"
              >
                <b-form-input
                  id="i-secondLastName"
                  v-model="formRegisterData.secondLastName"
                  placeholder=""
                />
              </b-form-group>
            </b-col>
            <!-- Fecha de nacimiento -->
            <b-col md="6">
              <b-form-group label="Fecha de nacimiento" label-for="i-birthday">
                <validation-provider
                  #default="{ errors }"
                  name="Fecha_de_nacimiento"
                  rules="required"
                >
                  <b-form-input
                    id="i-birthday"
                    v-model="formRegisterData.birthday"
                    type="date"
                    placeholder=""
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Género -->
            <b-col md="6" v-if="formRegisterData.accountType == '1'">
              <b-form-group label="Género" label-for="i-gender">
                <b-form-select
                  id="i-gender"
                  v-model="formRegisterData.gender"
                  :options="optionsGender"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- Ubicación y contacto -->
      <tab-content
        title="Ubicación y contacto"
        :before-change="validationFormUbication"
      >
        <validation-observer ref="ubicationRules">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Ubicación y contacto</h5>
              <small class="text-muted"
                >Ingresa tus datos de ubcación y contacto</small
              >
            </b-col>
            <!-- País -->
            <b-col md="6">
              <b-form-group label="País" label-for="i-country">
                <validation-provider
                  #default="{ errors }"
                  name="Pais"
                  rules="required"
                >
                  <b-form-select
                    id="i-country"
                    v-model="formRegisterData.country"
                    :options="optionsCountry"
                    @change="getDepartmentsFromService"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Departamento/Estado/Provincia -->
            <b-col md="6">
              <b-form-group
                label="Departamento/Estado/Provincia"
                label-for="i-department"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Departamento"
                  rules="required"
                >
                  <b-form-select
                    id="i-department"
                    v-model="formRegisterData.department"
                    :options="optionsDepartment"
                    @change="getCitiesFromService"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Ciudad -->
            <b-col md="6">
              <b-form-group label="Ciudad" label-for="i-city">
                <validation-provider
                  #default="{ errors }"
                  name="Ciudad"
                  rules="required"
                >
                  <b-form-select
                    id="i-city"
                    v-model="formRegisterData.city"
                    :options="optionsCity"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Barrio/Colonia -->
            <b-col md="6">
              <b-form-group label-for="i-neighborhood" label="Barrio/Colonia">
                <validation-provider
                  #default="{ errors }"
                  name="Barrio"
                  rules="required"
                >
                  <b-form-input
                    id="i-neighborhood"
                    placeholder=""
                    v-model="formRegisterData.neighborhood"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Código postal -->
            <b-col md="6">
              <b-form-group label-for="i-postcode" label="Código postal">
                <validation-provider
                  #default="{ errors }"
                  name="Codigo_postal"
                  rules="required"
                >
                  <b-form-input
                    id="i-postcode"
                    placeholder=""
                    v-model="formRegisterData.postcode"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Dirección -->
            <b-col md="6">
              <b-form-group label-for="i-address" label="Dirección">
                <validation-provider
                  #default="{ errors }"
                  name="Direccion"
                  rules="required"
                >
                  <b-form-input
                    id="i-address"
                    placeholder=""
                    v-model="formRegisterData.address"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Teléfono fijo -->
            <b-col md="6">
              <b-form-group label-for="i-homePhone" label="Teléfono fijo">
                <b-form-input
                  id="i-homePhone"
                  placeholder=""
                  v-model="formRegisterData.homePhone"
                />
              </b-form-group>
            </b-col>
            <!-- Teléfono celular -->
            <b-col md="6">
              <b-form-group label-for="i-cellPhone" label="Teléfono celular">
                <validation-provider
                  #default="{ errors }"
                  name="Telefono_celular"
                  rules="required"
                >
                  <b-form-input
                    id="i-cellPhone"
                    placeholder=""
                    v-model="formRegisterData.cellphone"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- WebSite -->
            <b-col md="6" v-if="formRegisterData.accountType == '2'">
              <b-form-group label="Página web" label-for="i-website">
                <b-form-input
                  id="i-website"
                  v-model="formRegisterData.website"
                  placeholder=""
                />
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <!-- Datos de la cuenta -->
      <tab-content title="Datos de tu cuenta">
        <validation-observer ref="accountRules">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Datos de tu cuenta</h5>
              <small class="text-muted">Ingresa los datos de tu cuenta</small>
            </b-col>
            <!-- Email -->
            <b-col md="6">
              <b-form-group label-for="i-email" label="Email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="i-email"
                    placeholder=""
                    v-model="formRegisterData.email"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Confirmar email -->
            <b-col md="6">
              <b-form-group label-for="i-emailConfirm" label="Confirmar Email">
                <validation-provider
                  #default="{ errors }"
                  name="Confirmar_Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="i-emailConfirm"
                    placeholder=""
                    v-model="formRegisterData.emailConfirmation"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Contraseña -->
            <b-col md="6">
              <b-form-group label-for="i-password" label="Contraseña">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="password"
                  vid="password"
                >
                  <b-form-input
                    id="i-password"
                    type="password"
                    v-model="formRegisterData.password"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Confirmar Contraseña -->
            <b-col md="6">
              <b-form-group
                label-for="i-passwordConfirm"
                label="Confirmar Contraseña"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirmar_Password"
                  rules="required|confirmed:password"
                >
                  <b-form-input
                    id="i-passwordConfirm"
                    type="password"
                    v-model="formRegisterData.passwordConfirmation"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Email alterno-->
            <b-col md="6">
              <b-form-group label-for="i-secondEmail" label="Email lterno">
                <validation-provider
                  #default="{ errors }"
                  name="Email Alterno"
                  rules="required|email"
                >
                  <b-form-input
                    id="i-secondEmail"
                    v-model="formRegisterData.emailSecondary"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- confirmar Email alterno-->
            <b-col md="6">
              <b-form-group
                label-for="i-secondEmailConfirm"
                label="Confirmar E-mail Alterno"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirmar E-mail Alterno"
                  rules="required|email"
                >
                  <b-form-input
                    id="i-secondEmailConfirm"
                    placeholder=""
                    v-model="formRegisterData.emailSecondaryConfirmation"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-checkbox
                value="1"
                class="custom-control-primary"
                v-model="formRegisterData.emailNotification"
              >
                Acepto recibir notificaciones por Email
              </b-form-checkbox>
            </b-col>
            <b-col md="12">
              <validation-provider
                #default="{ errors }"
                name="Acepto Terminos"
                rules="required"
              >
                <b-form-checkbox
                  value="1"
                  class="custom-control-primary"
                  v-model="formRegisterData.conditions"
                  :state="errors.length > 0 ? false : null"
                >
                  Acepto los términos y condiciones
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormSelect,
} from "bootstrap-vue";
import { required, email, confirmed, password } from "@validations";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selected: false,
      selectedContry: "select_value",
      selectedLanguage: "nothing_selected",
      titleTabInformation: "Información Personal",
      optionsAccountType: [
        { value: "", text: "Seleccione una opción" },
        { value: "1", text: "Persona" },
        { value: "2", text: "Empresa" },
      ],
      optionsIdentificationType: [],
      optionsGender: [
        { value: "", text: "Seleccione una opción" },
        { value: "1", text: "Masculino" },
        { value: "2", text: "Femenino" },
        { value: "3", text: "Otro" },
      ],
      optionsCountry: [],
      optionsDepartment: [],
      optionsCity: [],
      required,
      email,
      formRegisterData: {
        accountType: "",
        identificationType: "",
        identificationNumber: "",
        firstName: "",
        secondName: "",
        firstLastName: "",
        secondLastName: "",
        birthday: "",
        gender: "",
        email: "",
        emailConfirmation: "",
        emailSecondary: "",
        emailSecondaryConfirmation: "",
        password: "",
        passwordConfirmation: "",
        country: "",
        department: "",
        city: "",
        neighborhood: "",
        postcode: "",
        cellphone: "",
        address: "",
        homePhone: "",
        officeId: "",
        lockerId: "",
        emailNotification: "",
        conditions: "",
        companyName: "",
      },
    };
  },
  created() {
    this.getIdentificationsTypeFromService();
    this.getCountriesFromService();
  },
  methods: {
    onAccounTypeInput() {
      if (this.formRegisterData.accountType == "1") {
        this.titleTabInformation = "Información Personal";
      } else {
        this.titleTabInformation = "Información de la Empresa";
      }
    },
    getIdentificationsTypeFromService() {
      this.$http
        .get(`api/tiposdeidentificacion/${process.env.VUE_APP_APPID}`)
        .then((response) => {
          this.optionsIdentificationType = [];
          response.data.datos.forEach((idType) => {
            let itemIdType = {
              value: idType.id,
              text: idType.descripcion,
            };
            this.optionsIdentificationType.push(itemIdType);
          });
        })
        .catch((errors) => {
          // console.log(errors);
        });
    },
    getCountriesFromService() {
      this.$http
        .get(`api/casillero/clientes/getCountries/${process.env.VUE_APP_APPID}`)
        .then((response) => {
          response.data.forEach((country) => {
            let itemCountry = {
              value: country.codigo,
              text: country.nombre,
            };
            this.optionsCountry.push(itemCountry);
          });
        })
        .catch((errors) => {
          // console.log(errors);
        });
    },
    getDepartmentsFromService() {
      this.$http
        .get(
          `api/casillero/clientes/getDepartaments/${this.formRegisterData.country}/${process.env.VUE_APP_APPID}`
        )
        .then((response) => {
          this.optionsDepartment = [];
          response.data.forEach((department) => {
            let itemDepartment = {
              value: department.id,
              text: department.nombre,
            };
            this.optionsDepartment.push(itemDepartment);
          });
        })
        .catch((errors) => {
          // console.log(errors);
        });
    },
    getCitiesFromService() {
      this.$http
        .get(
          `api/casillero/clientes/getCities/${this.formRegisterData.department}/${process.env.VUE_APP_APPID}`
        )
        .then((response) => {
          this.optionsCity = [];
          response.data.forEach((city) => {
            let itemCity = {
              value: city.id,
              text: city.nombre,
            };
            this.optionsCity.push(itemCity);
          });
        })
        .catch((errors) => {
          // console.log(errors);
        });
    },
    registerClient() {
      this.$http
        .post("api/clientes", {
          tipo_de_cuenta_id: this.formRegisterData.accountType,
          tipo_de_identificacion_id: this.formRegisterData.identificationType,
          numero_de_identificacion: this.formRegisterData.identificationNumber,
          primer_nombre: this.formRegisterData.firstName,
          segundo_nombre: this.formRegisterData.secondName,
          primer_apellido: this.formRegisterData.firstLastName,
          segundo_apellido: this.formRegisterData.secondLastName,
          email: this.formRegisterData.email,
          email_confirmation: this.formRegisterData.emailConfirmation,
          password: this.formRegisterData.password,
          password_confirmation: this.formRegisterData.passwordConfirmation,
          pais_codigo: this.formRegisterData.country,
          departamento_id: this.formRegisterData.department,
          ciudad_id: this.formRegisterData.city,
          telefono_celular: this.formRegisterData.cellphone,
          direccion: this.formRegisterData.address,
          telefono_fijo: this.formRegisterData.homePhone,
          oficina_id: this.formRegisterData.officeId,
          codigo_casillero: this.formRegisterData.lockerCode,
          app_id: process.env.VUE_APP_APPID,
        })
        .then((response) => {
          this.$swal({
            title: "Creación Casillero",
            text: "¡Usuario Creado con éxito!",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          }).then((result) => {
            this.$router.push("/");
          });
        })
        .catch((errors) => {
          this.$swal({
            title: "Error Crear Cuenta",
            text: errors.response.data.errores,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.personalRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormUbication() {
      return new Promise((resolve, reject) => {
        this.$refs.ubicationRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormAccount() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
};
</script>
