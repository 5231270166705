var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form-wizard',{staticClass:"steps-transparent mb-3",attrs:{"color":"#FF8130","title":"Crear Cuenta","subtitle":"Diligencia el siguiente formulario para administrar tu casillero","finish-button-text":"Crear Cuenta","back-button-text":"Anterior","next-button-text":"Siguiente"},on:{"on-complete":_vm.registerClient}},[_c('tab-content',{attrs:{"title":_vm.titleTabInformation,"before-change":_vm.validationFormInfo}},[_c('validation-observer',{ref:"personalRules"},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Tipo de cuenta")]),_c('small',{staticClass:"text-muted"},[_vm._v(" Selecciona el tipo de cuenta ")])]),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"","label-for":"i-accountType"}},[_c('validation-provider',{attrs:{"name":"Tipo_de_Cuenta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"i-accountType","options":_vm.optionsAccountType,"state":errors.length > 0 ? false : null},on:{"change":function($event){return _vm.onAccounTypeInput()}},model:{value:(_vm.formRegisterData.accountType),callback:function ($$v) {_vm.$set(_vm.formRegisterData, "accountType", $$v)},expression:"formRegisterData.accountType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[(_vm.formRegisterData.accountType == '1')?_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Información personal")]),_c('small',{staticClass:"text-muted"},[_vm._v("Ingresa tu información personal")])]):_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Información de tu empresa")]),_c('small',{staticClass:"text-muted"},[_vm._v("Ingresa la información de tu empresa")])]),(_vm.formRegisterData.accountType == '1')?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tipo de identificación","label-for":"i-identificationType"}},[(_vm.formRegisterData.accountType == '1')?_c('validation-provider',{attrs:{"name":"Tipo_de_identificacion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"i-identificationType","options":_vm.optionsIdentificationType,"state":errors.length > 0 ? false : null},model:{value:(_vm.formRegisterData.identificationType),callback:function ($$v) {_vm.$set(_vm.formRegisterData, "identificationType", $$v)},expression:"formRegisterData.identificationType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3084453186)}):_vm._e()],1)],1):_vm._e(),(_vm.formRegisterData.accountType == '2')?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Razón social","label-for":"i-companyName"}},[(_vm.formRegisterData.accountType == '2')?_c('validation-provider',{attrs:{"name":"Razon_social","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"i-companyName","placeholder":"","state":errors.length > 0 ? false : null},model:{value:(_vm.formRegisterData.companyName),callback:function ($$v) {_vm.$set(_vm.formRegisterData, "companyName", $$v)},expression:"formRegisterData.companyName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,644666605)}):_vm._e()],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nit o Número de identificación","label-for":"i-identificationNumber"}},[_c('validation-provider',{attrs:{"name":"Numero_de_identificacion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"i-identificationNumber","state":errors.length > 0 ? false : null},model:{value:(_vm.formRegisterData.identificationNumber),callback:function ($$v) {_vm.$set(_vm.formRegisterData, "identificationNumber", $$v)},expression:"formRegisterData.identificationNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Primer nombre","label-for":"i-firstName"}},[_c('validation-provider',{attrs:{"name":"Primer_nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"i-firstName","state":errors.length > 0 ? false : null},model:{value:(_vm.formRegisterData.firstName),callback:function ($$v) {_vm.$set(_vm.formRegisterData, "firstName", $$v)},expression:"formRegisterData.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Segundo nombre","label-for":"i-secondName"}},[_c('b-form-input',{attrs:{"id":"i-secondName","placeholder":""},model:{value:(_vm.formRegisterData.secondName),callback:function ($$v) {_vm.$set(_vm.formRegisterData, "secondName", $$v)},expression:"formRegisterData.secondName"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Primer apellido","label-for":"i-firstLastName"}},[_c('validation-provider',{attrs:{"name":"Primer_apellido","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"i-firstLastName","placeholder":"","state":errors.length > 0 ? false : null},model:{value:(_vm.formRegisterData.firstLastName),callback:function ($$v) {_vm.$set(_vm.formRegisterData, "firstLastName", $$v)},expression:"formRegisterData.firstLastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Segundo apellido","label-for":"i-secondLastName"}},[_c('b-form-input',{attrs:{"id":"i-secondLastName","placeholder":""},model:{value:(_vm.formRegisterData.secondLastName),callback:function ($$v) {_vm.$set(_vm.formRegisterData, "secondLastName", $$v)},expression:"formRegisterData.secondLastName"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Fecha de nacimiento","label-for":"i-birthday"}},[_c('validation-provider',{attrs:{"name":"Fecha_de_nacimiento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"i-birthday","type":"date","placeholder":"","state":errors.length > 0 ? false : null},model:{value:(_vm.formRegisterData.birthday),callback:function ($$v) {_vm.$set(_vm.formRegisterData, "birthday", $$v)},expression:"formRegisterData.birthday"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.formRegisterData.accountType == '1')?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Género","label-for":"i-gender"}},[_c('b-form-select',{attrs:{"id":"i-gender","options":_vm.optionsGender},model:{value:(_vm.formRegisterData.gender),callback:function ($$v) {_vm.$set(_vm.formRegisterData, "gender", $$v)},expression:"formRegisterData.gender"}})],1)],1):_vm._e()],1)],1)],1),_c('tab-content',{attrs:{"title":"Ubicación y contacto","before-change":_vm.validationFormUbication}},[_c('validation-observer',{ref:"ubicationRules"},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Ubicación y contacto")]),_c('small',{staticClass:"text-muted"},[_vm._v("Ingresa tus datos de ubcación y contacto")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"País","label-for":"i-country"}},[_c('validation-provider',{attrs:{"name":"Pais","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"i-country","options":_vm.optionsCountry,"state":errors.length > 0 ? false : null},on:{"change":_vm.getDepartmentsFromService},model:{value:(_vm.formRegisterData.country),callback:function ($$v) {_vm.$set(_vm.formRegisterData, "country", $$v)},expression:"formRegisterData.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Departamento/Estado/Provincia","label-for":"i-department"}},[_c('validation-provider',{attrs:{"name":"Departamento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"i-department","options":_vm.optionsDepartment,"state":errors.length > 0 ? false : null},on:{"change":_vm.getCitiesFromService},model:{value:(_vm.formRegisterData.department),callback:function ($$v) {_vm.$set(_vm.formRegisterData, "department", $$v)},expression:"formRegisterData.department"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Ciudad","label-for":"i-city"}},[_c('validation-provider',{attrs:{"name":"Ciudad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"i-city","options":_vm.optionsCity,"state":errors.length > 0 ? false : null},model:{value:(_vm.formRegisterData.city),callback:function ($$v) {_vm.$set(_vm.formRegisterData, "city", $$v)},expression:"formRegisterData.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"i-neighborhood","label":"Barrio/Colonia"}},[_c('validation-provider',{attrs:{"name":"Barrio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"i-neighborhood","placeholder":"","state":errors.length > 0 ? false : null},model:{value:(_vm.formRegisterData.neighborhood),callback:function ($$v) {_vm.$set(_vm.formRegisterData, "neighborhood", $$v)},expression:"formRegisterData.neighborhood"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"i-postcode","label":"Código postal"}},[_c('validation-provider',{attrs:{"name":"Codigo_postal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"i-postcode","placeholder":"","state":errors.length > 0 ? false : null},model:{value:(_vm.formRegisterData.postcode),callback:function ($$v) {_vm.$set(_vm.formRegisterData, "postcode", $$v)},expression:"formRegisterData.postcode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"i-address","label":"Dirección"}},[_c('validation-provider',{attrs:{"name":"Direccion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"i-address","placeholder":"","state":errors.length > 0 ? false : null},model:{value:(_vm.formRegisterData.address),callback:function ($$v) {_vm.$set(_vm.formRegisterData, "address", $$v)},expression:"formRegisterData.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"i-homePhone","label":"Teléfono fijo"}},[_c('b-form-input',{attrs:{"id":"i-homePhone","placeholder":""},model:{value:(_vm.formRegisterData.homePhone),callback:function ($$v) {_vm.$set(_vm.formRegisterData, "homePhone", $$v)},expression:"formRegisterData.homePhone"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"i-cellPhone","label":"Teléfono celular"}},[_c('validation-provider',{attrs:{"name":"Telefono_celular","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"i-cellPhone","placeholder":"","state":errors.length > 0 ? false : null},model:{value:(_vm.formRegisterData.cellphone),callback:function ($$v) {_vm.$set(_vm.formRegisterData, "cellphone", $$v)},expression:"formRegisterData.cellphone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.formRegisterData.accountType == '2')?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Página web","label-for":"i-website"}},[_c('b-form-input',{attrs:{"id":"i-website","placeholder":""},model:{value:(_vm.formRegisterData.website),callback:function ($$v) {_vm.$set(_vm.formRegisterData, "website", $$v)},expression:"formRegisterData.website"}})],1)],1):_vm._e()],1)],1)],1),_c('tab-content',{attrs:{"title":"Datos de tu cuenta"}},[_c('validation-observer',{ref:"accountRules"},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Datos de tu cuenta")]),_c('small',{staticClass:"text-muted"},[_vm._v("Ingresa los datos de tu cuenta")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"i-email","label":"Email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"i-email","placeholder":"","state":errors.length > 0 ? false : null},model:{value:(_vm.formRegisterData.email),callback:function ($$v) {_vm.$set(_vm.formRegisterData, "email", $$v)},expression:"formRegisterData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"i-emailConfirm","label":"Confirmar Email"}},[_c('validation-provider',{attrs:{"name":"Confirmar_Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"i-emailConfirm","placeholder":"","state":errors.length > 0 ? false : null},model:{value:(_vm.formRegisterData.emailConfirmation),callback:function ($$v) {_vm.$set(_vm.formRegisterData, "emailConfirmation", $$v)},expression:"formRegisterData.emailConfirmation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"i-password","label":"Contraseña"}},[_c('validation-provider',{attrs:{"rules":"required","name":"password","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"i-password","type":"password","state":errors.length > 0 ? false : null},model:{value:(_vm.formRegisterData.password),callback:function ($$v) {_vm.$set(_vm.formRegisterData, "password", $$v)},expression:"formRegisterData.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"i-passwordConfirm","label":"Confirmar Contraseña"}},[_c('validation-provider',{attrs:{"name":"Confirmar_Password","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"i-passwordConfirm","type":"password","state":errors.length > 0 ? false : null},model:{value:(_vm.formRegisterData.passwordConfirmation),callback:function ($$v) {_vm.$set(_vm.formRegisterData, "passwordConfirmation", $$v)},expression:"formRegisterData.passwordConfirmation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"i-secondEmail","label":"Email lterno"}},[_c('validation-provider',{attrs:{"name":"Email Alterno","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"i-secondEmail","state":errors.length > 0 ? false : null},model:{value:(_vm.formRegisterData.emailSecondary),callback:function ($$v) {_vm.$set(_vm.formRegisterData, "emailSecondary", $$v)},expression:"formRegisterData.emailSecondary"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"i-secondEmailConfirm","label":"Confirmar E-mail Alterno"}},[_c('validation-provider',{attrs:{"name":"Confirmar E-mail Alterno","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"i-secondEmailConfirm","placeholder":"","state":errors.length > 0 ? false : null},model:{value:(_vm.formRegisterData.emailSecondaryConfirmation),callback:function ($$v) {_vm.$set(_vm.formRegisterData, "emailSecondaryConfirmation", $$v)},expression:"formRegisterData.emailSecondaryConfirmation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"value":"1"},model:{value:(_vm.formRegisterData.emailNotification),callback:function ($$v) {_vm.$set(_vm.formRegisterData, "emailNotification", $$v)},expression:"formRegisterData.emailNotification"}},[_vm._v(" Acepto recibir notificaciones por Email ")])],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Acepto Terminos","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"value":"1","state":errors.length > 0 ? false : null},model:{value:(_vm.formRegisterData.conditions),callback:function ($$v) {_vm.$set(_vm.formRegisterData, "conditions", $$v)},expression:"formRegisterData.conditions"}},[_vm._v(" Acepto los términos y condiciones ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }