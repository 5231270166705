<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo" to="/">
        <b-img fluid src="@/assets/images/logo/logo.png" alt="Fenix" />
      </b-link>
      <b-col cols="12" class="d-sm-flex align-items-center p-5">
          <FormRegister />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BImg, BRow, BCol, BLink } from 'bootstrap-vue'
import FormRegister from '@/views/authentication/Forms/FormRegister.vue'
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BImg,
    FormRegister,
    BLink,
  }
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
